<template>
    <div class="site-customer-list">
        <customer-search
            :total-count="totalCount"
            :selectedUserIds="searchParams.memberIds"
            @updateSearchParams="updateSearchParams"
        ></customer-search>
        <lbd-center-left-right>
            <template v-slot:left>
                <div class="site-customer-list-left">
                    <customer-list
                        v-loading="customerListLoading"
                        :total-count="totalCount"
                        :list="customerList"
                        @refresh="getList"
                        @updateSearchParams="updateSearchParams"
                    ></customer-list>
                    <el-pagination
                        :current-page="pager.current"
                        :page-sizes="[10, 20, 50]"
                        :page-size="pager.size"
                        class="customer-list-pagination"
                        layout="prev, pager, next, slot, total, sizes"
                        :total="pager.total"
                        v-if="customerList.length > 0"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                    >
                        <span class="pagination-text">
                            <span>
                                前往
                                <el-input v-model="pagerJump" @keyup.enter.native="handlePagerJump(pagerJump)"></el-input>
                                页
                            </span>
                            <span @click="handlePagerJump(pagerJump)">
                                跳转
                            </span>
                        </span>
                    </el-pagination>
                </div>
            </template>
            <template v-slot:right>
                <right-wrapper></right-wrapper>
            </template>
        </lbd-center-left-right>
        <!-- <div class="lbd-sign"></div> -->
    </div>
</template>
<script>
import LbdCenterLeftRight from '#/component/common/layout/lbd-center-left-right.vue';
import CustomerSearch from './component/customerSearch.vue';
import CustomerList from './component/customerList.vue';
import RightWrapper from './layout/rightWrapper.vue';
import customerListService from '#/js/service/customerListService.js';
export default {
    name:'CustomerWrapperList',
    componentName: 'CustomerWrapperList',
    components:{
        LbdCenterLeftRight,
        CustomerList,
        CustomerSearch,
        RightWrapper
    },
    data(){
        return{
            customerListLoading: false,
            pager: {
                current: 1,
                size: 10,
                total: 0
            },
            pagerJump: 0,
            totalCount: 0,
            searchParams: {
                isOrderByCustomerCreated: true,
                isOrderByDesc: true,
                location: '',
                firmName: '',
                memberIds: [],
                startDate: '',
                endDate: ''
            },
            customerList: [
                {
                    name: '平安',
                    customerId: 'ab10032b-1eb0-4efa-92b1-e583e6f7152d'
                }
            ]
        }
    },
    computed:{
        isCFuser(){
            return this.$store.state.user.userInfo.isCFUser;
        },
        isAdministrator() {
            return this.$store.state.user.userInfo.isAdministrator || false;
        },
        isCustomer() {
            return (this.$store.state.user.userInfo.privilegeCodeList || []).includes('Customer');
        }
    },
    mounted(){
        if(!this.isAdministrator && !this.isCustomer){
            shortTips("权限不足，请联系管理员")
            setTimeout(() => {
                location.href = "/Headhunting/#/";
            }, 2000)
        }

        this.getList();
        this.$on('refreshList', () => {
            this.pager.current = 1;
            this.getList();
        })
    },
    methods:{
        scrollTop() {
            // document.documentElement.scrollTop = 0;
            // document.body.scrollTop = 0;
            // document.querySelector('.layout-transition').scrollTop = 0;
            document.querySelector('.site-customer-list').scrollTop = 0;
        },
        getList() {
            let searchParams = Object.assign({}, this.searchParams);
            searchParams.start = (this.pager.current - 1) * this.pager.size;
            searchParams.take = this.pager.size;

            this.customerListLoading = true;
            customerListService.getCustomerList(searchParams)
                .then(res => {
                    this.totalCount = res.total;
                    this.pager.total = res.total;
                    this.customerList = res.list;
                    this.customerListLoading = false;
                })
        },
        handleSizeChange(val){
            setTimeout(() => {
                this.scrollTop();
            }, 0)
            this.pager.size = val;
            this.pager.current = 1;
            this.getList();
        },
        handleCurrentChange(current) {
            this.scrollTop();
            this.pager.current = current;
            this.getList();
        },
        handlePagerJump(pagerJump) {
            // if(pagerJump > Math.ceil(this.pager.total/this.pager.size)) {
            //     return;
            // } else {
            //     this.handleCurrentChange(pagerJump);
            // }
            pagerJump = Number.parseInt(pagerJump);
            if (
                pagerJump > 0 &&
                pagerJump <= Math.ceil(this.pager.total / this.pager.size)
            ) {
                this.handleCurrentChange(pagerJump);
            }
        },
        updateSearchParams(json) {
            this.searchParams = Object.assign({}, this.searchParams, json);
            this.pager.current = 1;
            this.getList();
        }
    }
}
</script>

<style lang="scss" scope>
@media screen and (max-width: 1400px) {
    .site-customer-list-left {
        width: calc(100% - 340px) !important;
    }
    .site-customer-list-right{
        width: 320px !important;
    }
}
@media screen and (min-width: 1400px) and (max-width: 1680px) {
    .site-customer-list-left {
        width: calc(100% - 400px) !important;
    }
    .site-customer-list-right{
        width: 380px !important;
    }
}

.site-customer-list {
    padding: 20px;
    height: 100%;
    overflow-y: auto;

    .lbd-sign {
        padding-top: 20px;
        &:after {
            content: '© 2023 liebide.com';
            margin-left: 50%;
            text-align: center;
            font-size: 14px;
            color: #999;
        }
    }

    .site-customer-list-left {
        background-color: #ffffff;
        border-radius: 8px;
        width: calc(100% - 440px);
    }
    .customer-list-pagination.el-pagination {
        padding: 40px 13px 20px 20px;
        font-weight: normal;
        .btn-next, .btn-prev {
            height: 38px;
            border: 1px solid;
        }
        .btn-prev {
            border-right: none;
            border-radius: 4px 0 0 4px;
        }
        .btn-next {
            border-left: none;
            border-radius: 0 4px 4px 0;
        }
        .el-pagination__sizes .el-input{
            width: 87px;
            .el-input__inner {
                color: #666;
                .el-select__caret{
                    color: #999;
                }
            }
        }
        .el-pager {
            border-top: 1px solid;
            border-bottom: 1px solid;
            li {
                min-width: 36px;
                height: 36px;
                line-height: 36px;
                font-size: 14px;
            }
        }
        .btn-next, .btn-prev, .el-pager {
            border-color: #DDDDDD;
        }
        .el-input__inner, .el-pagination__total, .el-pagination__jump {
            height: 38px;
            line-height: 38px;
            font-size: 14px;
        }
        .pagination-text{
            color: #999;
            span{
                height: 38px;
                line-height: 38px;
                font-size: 14px;
                .el-input{
                    width: 48px;
                    margin: 0 5px;
                    &__inner{
                        height: 38px;
                        line-height: 38px;
                        padding: 0 5px;
                    }
                }
                &:nth-of-type(2){
                    margin-left: 5px;
                    color: $primary;
                    cursor: pointer;
                }
            }
        }
        .el-pagination__total,
        .el-pagination__sizes,
        .pagination-text {
            float: right;
        }
    }
}
</style>

<style lang="scss">
body .layout-transition .site-customer-list{
    margin: 20px auto 30px;
    padding: 0;
    width: 1200px;
    min-height: 100%;
    background-color: rgba(0, 0, 0, 0);
}
</style>